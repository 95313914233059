/* Add this CSS to your stylesheet or in a style tag in your component */

/* Custom Scrollbar Styles */
.textarea-custom-scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.textarea-custom-scroll::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, rgba(56, 189, 248, 0.9), rgba(56, 189, 248, 0.6));
    border-radius: 10px;
}

.textarea-custom-scroll::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(180deg, rgba(56, 189, 248, 1), rgba(56, 189, 248, 0.8));
}

.textarea-custom-scroll::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}