.overflow-y-auto::-webkit-scrollbar {
    display: none;
  }

  .blur-lg {
    filter: blur(100px);
  }

  img:hover {
    transition: transform 0.3s ease-in-out;
    z-index: 20; /* Ensure it's above other content */
  }
  
  .image-box {
    height: 50px;
    overflow: hidden;
    margin-bottom: 4px;
  }
  
  @keyframes pulseBlur {
    0%, 100% {
      transform: scale(1);
      filter: blur(20px);
    }
    50% {
      transform: scale(1.05);
      filter: blur(50px);
    }
  }
  
  .blur-moving {
    animation: pulseBlur 1s infinite ease-in-out;
  }
  