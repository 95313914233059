/* Styles for the Profile Edit Form modal */
.profile-edit-modal .modal-content {
  background: linear-gradient(to right, #000000, #66a6ff);
  color: #333; /* Dark text for readability */
}

.profile-edit-modal .form-control {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ddd;
}

.profile-edit-modal .modal-header, .profile-edit-modal .modal-footer {
  background: rgba(255, 255, 255, 0.9); /* Slightly transparent to blend with the gradient */
}

.profile-edit-modal .modal-header .modal-title {
  color: #204969; /* Dark blue that matches the gradient */
}

.profile-edit-modal .modal-footer button {
  background-color: #66a6ff;
  border-color: #5599ff;
  color: white;
}

.profile-edit-modal .modal-footer button:hover {
  background-color: #5599ff;
  border-color: #4488ee;
}
