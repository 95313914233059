/* ResizableBox.css */
.uploadArea {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 5px dashed #ccc;
    color: #aaa;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    transition: border-color 0.3s;
    cursor: pointer;
}

.uploadArea:hover {
    border-color: skyblue;
}

.uploadArea:hover::before,
.uploadArea:hover::after {
    content: '';
    position: absolute;
    background-color: skyblue;
    transition: background-color 0.3s;
}

.uploadArea:hover::before {
    width: 20px;
    height: 20px;
    top: -10px;
    left: -10px;
    border-top: 5px solid skyblue;
    border-left: 5px solid skyblue;
}

.uploadArea:hover::after {
    width: 20px;
    height: 20px;
    bottom: -10px;
    right: -10px;
    border-bottom: 5px solid skyblue;
}

.rotator {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: gray;
    cursor: pointer;
}

.resizer {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: rgb(46, 212, 253);
    cursor: se-resize;
}

.rotator {
    right: 0;
    top: 0;
    cursor: crosshair;
}

.resizable {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: relative;
}
