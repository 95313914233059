.resize-icon {
  position: absolute;
  top: 50%;
  right: -43px;
  transform: translate3d(-50%, -50%, 0);
  color: #000;
  background: rgba(255, 255, 255, 0.8);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: 0 0 6px rgb(0 0 0 / 0%);
  z-index: 10;
}

.resize-icon:after,
.resize-icon:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  border-top: solid 2px;
  border-left: solid 2px;
  transform-origin: 0 0;
}

.resize-icon:before {
  left: 8px;
  transform: rotate(-45deg);
}

.resize-icon:after {
  left: 38px;
  transform: rotate(135deg);
}

.resize-save {
  position: absolute;
  top: 0;
  right: 5px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  border-right: 2px solid rgba(255, 255, 255, 0.8);
}

.resize-container {
  position: relative;
  width: 325px;
  height: 433px;
  resize: horizontal;
  opacity: 0;
  overflow: scroll;
  z-index: 1000;
}

.resize-container::-webkit-scrollbar {
  width: 10px;
  height: 433px;
  z-index: 1000;
  cursor: ew-resize;
}


.overview-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 50px;
  padding: 20px;
  margin: auto;
}

.section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 20px;
}

.section:nth-child(odd) .content,
.section:nth-child(even) .illustration {
  order: 1; /* Text appears first on mobile */
}

.section:nth-child(odd) .illustration,
.section:nth-child(even) .content {
  order: 2; /* Image appears second on mobile */
}

.illustration-placeholder {
  height: 200px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border: 1px dashed #ccc;
}

@media (max-width: 768px) {
  .overview-container {
      gap: 10px; /* Reduce gap for smaller screens */
      padding: 10px; /* Less padding on smaller screens */
  }

  .section {
      grid-template-columns: 1fr; /* Stack columns on small screens */
      gap: 10px; /* Smaller gap for better use of space */
  }

  .illustration-placeholder {
      height: 150px; /* Smaller illustrations on smaller screens */
      font-size: 14px; /* Adjust font size for smaller illustrations */
  }
}

.horizontal-bar {
  color: #0d6efd;
}

/* Overview.css */
.video-container {
  position: relative;
  padding: 2px;
  border-radius: 15px; /* Rounded corners for the container */
}

.video-container video {
  display: block; /* Removes bottom space/gap */
  width: 100%;
  height: auto;
  border-radius: 5px; /* Rounded corners for the video */
}

.video-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.video-nav:hover {
  background: rgba(0,0,0,0.7);
}

.video-nav:first-of-type {
  left: 10px;
}

.video-nav:last-of-type {
  right: 10px;
}
