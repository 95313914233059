.design-thumbnail {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.thumbnail-stack {
    position: relative;
    width: 100%;
    height: 100%;
}

.thumbnail-image {
    position: absolute;
    width: 90%;
    height: 90%;
    object-fit: cover;
    border: 2px solid #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.thumbnail-image.top {
    z-index: 3;
    top: 0;
    left: 0;
}

.thumbnail-image.middle {
    z-index: 2;
    top: 10px;
    left: 10px;
}

.thumbnail-image.bottom {
    z-index: 1;
    top: 20px;
    left: 20px;
}

.design-thumbnail:hover .thumbnail-image.middle {
    transform: translate(10px, -10px);
}

.design-thumbnail:hover .thumbnail-image.bottom {
    transform: translate(20px, -20px);
}

.design-name-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    text-align: center;
    padding: 4px 0;
    z-index: 4;
}