.pencilBar {
  z-index: 2;
  position: fixed;
  background-color: var(--primary-bg-color);
  left: 140%;
  justify-content: center;
  transform: translateX(-50%);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  box-shadow: 0 5px 10px 2px var(--shadow-color),
    0 5px 10px 2px var(--shadow-color);
  width: 75%;
  top: 0px;
}

.pencilOptionsDropdown {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.pencilOptionsDropdown .form-group {
  margin-bottom: 20px;
}

.pencilOptionsDropdown form {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(255, 17, 17, 0.1);
}

.pencilOptionsDropdown label {
  font-weight: normal;
  font-size: .8rem;
  margin-bottom: 5px;
}

.pencilOptionsDropdown input[type="number"],
.pencilOptionsDropdown input[type="color"] {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Enhanced slider appearance */
.pencilOptionsDropdown input[type="range"] {
  height: 1px;
  background: #fdedff;
  border-radius: 5px;
}

.pencilOptionsDropdown input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #007bff;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;
}

.pencilOptionsDropdown input[type="range"]:hover::-webkit-slider-thumb {
  background: #0056b3;
}

.pencilOptionsDropdown input[type="range"]:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
}

/* color-circle-style.css */

.colorCircle {
  justify-content: center;
  align-items: center;
}

.circleColorInput {
  display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    /* Fixed width for preview box */
    height: 50px;
    /* Fixed height for preview box */
    border: 2px solid var(--border-color);
    border-radius: 8px;
    background-color: #f5f5f5;
    position: relative;
}

.circleColorInput::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 50%;
}

.circleColorInput::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

.colorCircle {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Size slider and preview */
.size-slider-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.size-slider {
  -webkit-appearance: none;
  width: 80%;
  background: #ddd;
  height: 6px;
  border-radius: 5px;
  margin-right: 10px;
}

.size-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: var(--primary-color);
  cursor: pointer;
  border-radius: 50%;
  transition: background 0.3s;
}

.size-slider::-webkit-slider-thumb:hover {
  background: var(--highlight-color);
}

.size-slider::-webkit-slider-runnable-track {
  height: 6px;
  background: #ddd;
  border-radius: 5px;
}

.size-preview-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  /* Fixed width for preview box */
  height: 50px;
  /* Fixed height for preview box */
  border: 2px solid var(--border-color);
  border-radius: 8px;
  background-color: #201010;
  position: relative;
}

.size-preview-inner {
  display: inline-block;
  background-color: var(--primary-color);
  border-radius: 50%;
  border: 1px solid var(--border-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.size-label {
  margin-left: 10px;
  font-size: 0.875rem;
  /* Slightly smaller font for size display */
}